"use client";

import React, { useEffect, useLayoutEffect, useState } from "react";
import { COUNTRY_LIST } from "./_lib/constant/country-constant";
import serverURL from "./_lib/constant/serverURL";
import axios from "axios";
import { USER_ROLE } from "./_lib/constant/user-constant";
import { usePathname, useRouter } from "next/navigation";
import { platform } from "os";

export const PageContext = React.createContext(null);

export function Providers({ children }: { children: any }) {
  const router = useRouter();
  const location = usePathname();
  // @ts-ignore
  let language;
  const [country, setCountry] = useState("");
  const [user, setUser]: any = useState({
    id: -1,
    roles: USER_ROLE.NORMAL_USER,
  });

  const [firstRenderChk, setFirstRenderChk] = useState(false);
  useEffect(() => {
    language = String(
      // @ts-ignore
      navigator?.language || navigator?.userLanguage
    )?.slice(0, 2);
    setCountry(
      // @ts-ignore
      language === COUNTRY_LIST.인도 ||
        language === COUNTRY_LIST.프랑스 ||
        language === COUNTRY_LIST.스페인 ||
        language === COUNTRY_LIST.중국 ||
        language === COUNTRY_LIST.일본 ||
        language === COUNTRY_LIST.한국 ||
        language === COUNTRY_LIST.미국 ||
        language === COUNTRY_LIST.독일 ||
        language === COUNTRY_LIST.이탈리아 ||
        language === COUNTRY_LIST.러시아 ||
        language === COUNTRY_LIST.터키 ||
        language === COUNTRY_LIST.포르투칼 ||
        language === COUNTRY_LIST.태국 ||
        language === "en"
        ? language
        : "en"
    );
    async function loginProcess() {
      try {
        const email = window.localStorage.getItem("email");
        const password = window.localStorage.getItem("password");
        const sns = window.localStorage.getItem("sns");
        const snsId = window.localStorage.getItem("snsId");
        if (!email) {
          setFirstRenderChk(true);
          return;
        }
        await axios
          .post(`${serverURL}/user/loginLocal`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            email,
            password,
            sns,
            snsId,
          })
          .then(async (res) => {
            if (res.data.status === "true") {
              const user = res.data.user;
              const accessToken = res.data.accessToken;
              const refreshToken = res.data.refreshToken;
              window.localStorage.setItem("accessToken", accessToken);
              window.localStorage.setItem("refreshToken", refreshToken);
              setUser(user);
              setFirstRenderChk(true);
            }
          })
          .catch((err) => {
            setFirstRenderChk(true);
          });
      } catch (err) {
        setFirstRenderChk(true);
        //console.error(err)
      }
    }
    loginProcess();
  }, []);

  useEffect(() => {
    if (
      user?.id === -1 &&
      firstRenderChk &&
      (String(location).toLowerCase() === "/" ||
        String(location).toLowerCase() === "/admin/" ||
        String(location).toLowerCase() === "/setting/" ||
        String(location).toLowerCase() === "/profilesetting/" ||
        String(location).toLowerCase() === "/cart/" ||
        // String(location).toLowerCase().slice(0, 9) === "/profile/" ||
        String(location).toLowerCase().slice(0, 17) === "/pictorialcreate/")
      // String(location).toLowerCase().slice(0, 11) === "/pictorial/"
    ) {
      router.push("/login");
    }
  }, [user, firstRenderChk]);

  const result: any = {
    country,
    user,
    setUser,
  };

  return firstRenderChk ? (
    <PageContext.Provider value={result}>{children}</PageContext.Provider>
  ) : null;
}
