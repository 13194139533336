const USER_ROLE: {
    NORMAL_USER: number,
    COMPANY_USER: number,
    BAN_USER: number,
    CS_USER: number,
    ADMIN_USER: number,
    DESTROY_USER: number,
    isNormalRole: any,
    isCompanyRole: any,
    isBanRole: any,
    isCsRole: any,
    isAdminRole: any,
    isDestoryRole: any,
    CREATOR_USER: number;
    isCreatorRole: any;
    WAIT_USER: number;
    isWaitRole: any;
} = (function () {
    return {
        // 1 일반 유저 //2 mcn //3 ban //4 cs // 5 관리자
        NORMAL_USER: 1,
        COMPANY_USER: 2,
        BAN_USER: 3,
        CS_USER: 4,
        ADMIN_USER: 5,
        DESTROY_USER: 6,
        CREATOR_USER: 7,
        WAIT_USER: 8,

        isNormalRole: (role: number): boolean => USER_ROLE.NORMAL_USER === role,
        isCompanyRole: (role: number): boolean => USER_ROLE.COMPANY_USER === role,
        isBanRole: (role: number): boolean => USER_ROLE.BAN_USER === role,
        isCsRole: (role: number): boolean => USER_ROLE.CS_USER === role,
        isAdminRole: (role: number): boolean => USER_ROLE.ADMIN_USER === role,
        isDestoryRole: (role: number): boolean => USER_ROLE.ADMIN_USER === role,
        isCreatorRole: (role: number): boolean => USER_ROLE.CREATOR_USER === role,
        isWaitRole: (role: number): boolean => USER_ROLE.WAIT_USER === role,
    }
}())

export {
    USER_ROLE,
}