const COUNTRY_LIST: {
    인도: string,
    프랑스: string,
    스페인: string,
    중국: string,
    일본: string,
    한국: string,
    미국: string,
    독일: string,
    이탈리아: string,
    러시아: string,
    터키: string,
    포르투칼: string,
    태국: string,
} = (function () {
    return {
        인도: 'id',
        프랑스: 'fr',
        스페인: 'es',
        중국: 'zh',//(간체)
        일본: 'ja',
        한국: 'ko',
        미국: 'en',
        독일: 'de',
        이탈리아: 'it',
        러시아: 'ru', //러시아 이용불가능
        터키: 'tr',
        포르투칼: 'pt',
        태국: 'th',
    }
}())

export {
    COUNTRY_LIST,
}